import React, { ForwardedRef, forwardRef } from "react";
import { Autocomplete, AutocompleteProps } from "@mui/material";

import { TextField, TextFieldProps } from "./text-field";

export const AutocompleteField = forwardRef(
  <
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
  >(
    {
      autocompleteProps,
      textFieldProps,
    }: {
      textFieldProps: TextFieldProps;
      autocompleteProps: Omit<
        AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
        "renderInput"
      >;
    },
    ref: ForwardedRef<HTMLInputElement>,
  ) => (
    <Autocomplete
      ref={ref}
      size="small"
      fullWidth
      clearIcon={null}
      clearOnBlur={false}
      blurOnSelect
      sx={{
        "& .MuiInputBase-input.Mui-disabled": {
          WebkitTextFillColor: "#000000",
        },
        "& > div > div > div": {
          height: "36px",
          borderRadius: "8px",
          color: "#354950",
          "& input": {
            padding: "0 0 0 8px !important",
          }
        },
      }}
      {...autocompleteProps}
      renderInput={(params) => <TextField {...params} {...textFieldProps} />}
    />
  ),
);
