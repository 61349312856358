import SendIcon from "@mui/icons-material/Send";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import moment from "moment";
import React, { useEffect } from "react";
import { CSVLink } from "react-csv";

import { MarginWrapper } from "components";

import { useEmissionFactors, useEmissionFactorsBySite, useScientificUnit } from "hooks";

import { DashboardTabs } from "pages/authenticated/dashboards/components/dashboardTabs";

import { useNewBreadcrumb } from "recoils";

import { getEmissionFactorsBySiteId } from "api";
import { useMutation } from "@tanstack/react-query";

import { EMISSION_FACTORS_PAGE } from "./consts";
import { Currency } from "./currency";
import { EmissionFactors } from "./emission-factors";
import { ScientificUnit } from "./scientific-unit";
import { EmissionFactorBySite } from "./emission-factor-by-site";


export const EmissionFactorsPage = () => {
  useNewBreadcrumb(EMISSION_FACTORS_PAGE.TITLE);

  // ---- Hooks ----
  // eslint-disable-next-line no-restricted-globals
  const queryParams = new URLSearchParams(location.search);
  const siteId = queryParams.get('s'); 

  // ---- States ----
  const [value, setValue] = React.useState("");
  const { data: references } = useEmissionFactors();
  const { data: emissionFactorsData } = useEmissionFactorsBySite();
  const { data: emissionFactorsDataById, mutateAsync: emissionFactorMutation  } = useMutation(getEmissionFactorsBySiteId);
  const { data: scientificunitandcurrency } = useScientificUnit();
  const tableRows = references || [];
  const scientificUnit = scientificunitandcurrency?.scientificUnit;
  const currency = scientificunitandcurrency?.currency;

  const CsvMatchedData = () => {
    let selectedData: any = [];

    if (value === "Emission Factors") {
      selectedData = tableRows?.map((item: any) => ({
        "Emission Factor Type": item?.emissionFactorType,
        "Utility TypeName": item?.utilityTypeName,
        "Utility SubType": item?.utilitySubtype,
        "Utility Type System Unit": item?.measurementUnit,
        "Valid From": item?.validFrom
          ? moment(item?.validFrom).utc().format("DD-MM-YYYY")
          : "",
        "Valid To": item?.validTo
          ? moment(item?.validTo).utc().format("DD-MM-YYYY")
          : "",
        Region: item?.region,
        Country: item?.country,
        "Country Region": item?.countryRegion,
        "Apply To": item?.applyTo,
        "GWP Multipliers": item?.gwpMultipliers,
        "GWP Source": item?.gwpSource,
        "CO2eq EF": item?.co2ConversionRatio,
        "CO2eq EF Unit": item?.co2ConversionUnit,
        "CO2 EF": item?.co2EmissionFactorRatio,
        "CO2 EF Unit": item?.co2EmissionFactorUnit,
        "CH4 Fossil EF": item?.ch4FossilEmissionFactorRatio,
        "CH4 EF Unit": item?.ch4FossilEmissionFactorUnit,
        "CH4 Non-Fossil EF": item?.ch4NonFossilEmissionFactorRatio,
        "CH4 Non-Fossil EF Unit": item?.ch4NonFossilEmissionFactorUnit,
        "N2O EF": item?.n2oEmissionFactorRatio,
        "N2O EF Unit": item?.n2oEmissionFactorUnit,
        Source: item?.source,
        " Source Update Date": item?.sourceUpdateDate,
        "ESG Flo Access Date": item?.esgFloAccessDate
          ? moment(item?.esgFloAccessDate).utc().format("DD-MM-YYYY")
          : "",
        "Source Link": item.sourceLink,
      }));
    } else if (value === "Currency") {
      const parsedData = currency?.map((str: string, index: any) => {
        try {
          return JSON.parse(str);
        } catch (error) {
          console.error(`Error parsing string at index ${index}:`, error);
          return null;
        }
      });

      selectedData = parsedData?.map((item: any) => ({
        From: item.fromCurrency,
        To: item.toCurrency,
        Conversion: item.conversionValue,
        "Last Updated": item.updatedDate
          ? moment(item.updatedDate).utc().format("DD-MM-YYYY")
          : "",
      }));
    } else if (value === "Scientific Unit") {
      const parsedData = scientificUnit?.map((str: string, index: any) => {
        try {
          return JSON.parse(str);
        } catch (error) {
          console.error(`Error parsing string at index ${index}:`, error);
          return null;
        }
      });
      selectedData = parsedData?.map((item: any) => ({
        UtilityType: item.utilityType,
        UtilitySubType: item.utilitySubtype,
        From: item.toUnit,
        To: item.fromUnit,
        Conversion: item.conversionValue,
        "Last Updated": item.updatedDate
          ? moment(item.updatedDate).utc().format("DD-MM-YYYY")
          : "",
      }));
    } else {
      const data = siteId ? emissionFactorsDataById: emissionFactorsData;

      selectedData = data?.map((item: any) => ({
        "Customer Site Id": item?.customerSiteId,
        "Site Name": item?.siteName,
        "Emission Factor Type": item?.utilityTypeConversions?.utilityTypeConversions?.emissionFactorType,
        "Utility TypeName": item?.utilityTypeConversions?.utilityTypeName,
        "Utility SubType": item?.utilityTypeConversions?.utilitySubtype,
        "Utility Type System Unit": item?.utilityTypeConversions?.measurementUnit,
        "Valid From": item?.utilityTypeConversions?.validFrom
          ? moment(item?.utilityTypeConversions?.validFrom).utc().format("DD-MM-YYYY")
          : "",
        "Valid To": item?.utilityTypeConversions?.validTo
          ? moment(item?.utilityTypeConversions?.validTo).utc().format("DD-MM-YYYY")
          : "",
        Region: item?.utilityTypeConversions?.region,
        Country: item?.utilityTypeConversions?.country,
        "Country Region": item?.utilityTypeConversions?.countryRegion,
        "Apply To": item?.utilityTypeConversions?.applyTo,
        "GWP Multipliers": item?.utilityTypeConversions?.gwpMultipliers,
        "GWP Source": item?.utilityTypeConversions?.gwpSource,
        "CO2eq EF": item?.utilityTypeConversions?.co2ConversionRatio,
        "CO2eq EF Unit": item?.utilityTypeConversions?.co2ConversionUnit,
        "CO2 EF": item?.utilityTypeConversions?.co2EmissionFactorRatio,
        "CO2 EF Unit": item?.utilityTypeConversions?.co2EmissionFactorUnit,
        "CH4 Fossil EF": item?.utilityTypeConversions?.ch4FossilEmissionFactorRatio,
        "CH4 EF Unit": item?.utilityTypeConversions?.ch4FossilEmissionFactorUnit,
        "CH4 Non-Fossil EF": item?.utilityTypeConversions?.ch4NonFossilEmissionFactorRatio,
        "CH4 Non-Fossil EF Unit": item?.utilityTypeConversions?.ch4NonFossilEmissionFactorUnit,
        "N2O EF": item?.utilityTypeConversions?.n2oEmissionFactorRatio,
        "N2O EF Unit": item?.utilityTypeConversions?.n2oEmissionFactorUnit,
        Source: item?.utilityTypeConversions?.source,
          "Standardized CO2eq EF": `${item?.utilityTypeConversions?.standardizedCo2ConversionRatio ?? 0} T/kWh`,
          "Standardized CO2 EF": `${item?.utilityTypeConversions?.standardizedCo2OnlyEmissionFactorRatio ?? 0} T/kW`,
          "Standardized CH4 Fossil EF": `${item?.utilityTypeConversions?.standardizedCh4FossilEmissionFactorRatio ?? 0 } T/kWh`,
          "Standardized CH4 Non-Fossil EF": `${item?.utilityTypeConversions?.standardizedCh4NonFossilEmissionFactorRatio ?? 0} T/kWh`,
          "Standardized N2O EF": `${item?.utilityTypeConversions?.standardizedN2oEmissionFactorRatio ?? 0} T/kWh`,
        " Source Update Date": item?.utilityTypeConversions?.sourceUpdateDate,
        "ESG Flo Access Date": item?.utilityTypeConversions?.esgFloAccessDate
          ? moment(item?.utilityTypeConversions?.esgFloAccessDate).utc().format("DD-MM-YYYY")
          : "",
        "Source Link": item.sourceLink,
      }));
    }
    return selectedData;
  };

  const leagalBtnStates = {
    LEGAL: "References",
  };

  const { LEGAL } = leagalBtnStates;
  
  const tabs: any = {
    [LEGAL]: [
      {
        label: "Emissions Factors",
        content: <EmissionFactors ReferencesData={tableRows} />,
      },
      {
        label: "Currency",
        content: <Currency ReferencesData={currency} />,
      },
      {
        label: "Scientific Unit",
        content: <ScientificUnit ReferencesData={scientificUnit} />,
      },
      {
        label: "Emission Factors by Site",
        content: <EmissionFactorBySite ReferencesData={siteId ? emissionFactorsDataById: emissionFactorsData} />,
      },
    ],
  };

  const handleTabChange = (tabName: any) => {
    if (tabName === 0) {
      setValue("Emission Factors");
    } else if (tabName === 1) {
      setValue("Currency");
    } else if (tabName === 2) {
      setValue("Scientific Unit");
    } else {
      setValue("Emission Factors By Site");
    }
  };

  useEffect(() => {
    if (siteId) {
      emissionFactorMutation(siteId)
    }
  },[siteId, emissionFactorMutation])

  return (
    <MarginWrapper>
      <Box mb={4}>
        <CSVLink
          style={{ textDecoration: "none" }}
          data={CsvMatchedData() ?? []}
          filename={`${value.replace(" ", "-")}.csv`}
        >
          <Button variant="contained" endIcon={<SendIcon />}>
            Export CSV
          </Button>
        </CSVLink>
      </Box>

      <DashboardTabs
        intensity
        refrences
        tabs={tabs[LEGAL]}
        selectedBtnOption={LEGAL}
        onTabChange={handleTabChange}
      />
    </MarginWrapper>
  );
};
