import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  styled,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import React, { FC, useEffect, useMemo, useState } from "react";

import {
  addUtilityThreshold,
  deleteUtilityThreshold,
} from "api";

import { COLORS, Label12SemiBold, Tooltip, TYPOGRAPHY_COMMON_STYLES, usePagination } from "components";

import { useNotify } from "hooks";

import { LOCAL_STORAGE_KEYS } from "consts";

import { Controller, useForm } from "react-hook-form";
import { EsgDeleteIcon, EsgDropDownIcon } from "assets";
import { useFilteredSitesMultiselect } from "hooks/multiselect/filteredSites";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";

interface ThresholdValueModalProps {
  isOpen: boolean;
  onClose: () => void;
  allowedValues?: any;
  showException?: boolean;
  utilityThresholdData: any;
}

interface UtilityType {
  id: number | null;
  name: string;
  allowedUnits: string[];
  unitDescription: string[];
  financials: boolean;
  emissions: boolean;
  consumptions: boolean;
}

interface SiteOption {
  label: string;
  value: number | null;
}

const CustomSelect = styled(Select)(() => ({
  '&.MuiOutlinedInput-root': {
    borderRadius: '8px', // Apply border radius
    paddingRight: '8px',
    '& fieldset': {
      borderColor: '#054254 !important', // Ensure border color is applied
    },
    '&:hover fieldset': {
      borderColor: '#054254 !important', // Change border color on hover
    },
    '&.Mui-focused fieldset': {
      borderColor: '#054254 !important', // Ensure border color when focused
    },
    '& .MuiSelect-icon': {
      top: '50%',
      transform: 'translateY(-50%)',
      right: '8px',
    },
  },
}));

const CustomOutlinedInput = styled(OutlinedInput)(() => ({
  '&.MuiOutlinedInput-root': {
    borderRadius: '8px',
    paddingRight: '8px', // Adjust padding to avoid extra space
    '& fieldset': {
      borderColor: '#054254',
    },
    '&:hover fieldset': {
      borderColor: '#054254',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#054254',
    },
  },
  fontSize: '16px',
  fontWeight: '600',
  color: '#054254',
  width: '242px',
  height: '36px',
  padding: '0px 12px',
}));

const CustomSmallOutlinedInput = styled(OutlinedInput)(() => ({
  '&.MuiOutlinedInput-root': {
    borderRadius: '8px',
    paddingRight: '8px',
    '& fieldset': {
      borderColor: '#054254',
    },
    '&:hover fieldset': {
      borderColor: '#054254',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#054254',
    },
  },
  fontSize: '14px',
  fontWeight: '600',
  color: '#054254',
  width: '90px',
  height: '36px',
  padding: '0px 12px',
}));

const tableStyle = {
  "& thead tr:first-of-type": {
    "th:first-of-type": {
      paddingLeft: "20px",
    },
    "th > div > div > div": {
      whiteSpace: "nowrap",
    }
  },
  "& tbody tr td:first-of-type": {
    paddingLeft: "20px",
  },
  "& tbody tr:first-of-type td > p": {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  height: "calc(100vh - 500px)",
  border: "1px solid #D9D9D9",
  borderRadius: "10px",
  minWidth: "100%",
  width: "100%",
  maxWidth: "100%",
  "@media (min-width: 600px)": {
    width: "100%",
  },
  "@media (min-width: 960px)": {
    width: "1100px",
  },
  "@media (min-width: 1280px)": {
    width: "1100px",
  },
  "@media (min-width: 1440px)": {
    width: "1100px",
  },
  "@media (min-width: 1680px)": {
    width: "1300px",
  },
  "@media (min-width: 2400px)": {
    width: "100%",
  },
}

export const ThresholdValueModal: FC<ThresholdValueModalProps> = ({
  isOpen,
  onClose,
  allowedValues,
  showException,
  utilityThresholdData,
}) => {

  // ---- Hook ----
  const notify = useNotify();

  // ---- State ----
  const [YoYInputValue, setYoYInputValue] = useState("");
  const [MoMInputValue, setMoMInputValue] = useState("");
  const [utilityType, setUtilityType] = useState<string>();
  const [selectedSites, setSelectedSites] = useState()
  const [utilityTypeOptions, setUtilityTypeOptions] = useState<UtilityType[]>([])
  const [siteOptions, setSiteOptions] = useState<SiteOption[]>([])
  
  const { control } = useForm<any>({
    mode: "onChange",
  });


  // ---- State ----


  // ---- Const ----

  // ---- API ----
  const { mutateAsync: addUtilityThresholds } = useMutation(addUtilityThreshold);
  const { mutateAsync: deleteUtilityThresholds } = useMutation(deleteUtilityThreshold);

  // ---- Use Effect ----


  // ---- Page Function ----
  const handleClose = () => {
    onClose();
  };

  const handleMoMChange = (e: any) => {
    const { target } = e;
    const inputVal = target;
    setMoMInputValue(inputVal.value);
  };

  const handleYoYChange = (e: any) => {
    const { target } = e;
    const inputVal = target;
    setYoYInputValue(inputVal.value);
  };

  const {
    setInitialPage,
    updateQueryParamsWithInitialPage,
  } = usePagination({
    pageSizeLocalStorageKey: LOCAL_STORAGE_KEYS.DOCUMENT_SUMMARY_PAGE_SIZE,
  });

  const { sitesOptions } =
    useFilteredSitesMultiselect({
      updateExternalStates: setInitialPage,
      getQueryParamsWithExternalChanges: updateQueryParamsWithInitialPage,
    });

  const handleSaveChange = () => {
    const payload = {
      utilityType: utilityType === "All Utility Type" ? null : utilityType,
      "siteId": selectedSites,
      "sequentialThreshold": MoMInputValue,
      "yearlyThreshold": YoYInputValue
    }
    addUtilityThresholds(payload, {
      onSuccess: () => {
        notify.success("Threshold values added!")
        handleClose();
      },
      onError: () => {
        notify.error("Some error has happen while adding Threshold values!")
      }
    })
  }
  const columns: MRT_ColumnDef<any>[] = [
    {
      accessorKey: "id",
      header: '', 
      // eslint-disable-next-line
      Cell: ({ row }) => {
        const rowId = row?.original?.id;
        return (
          <Box
            sx={{
              display: "flex",
              borderRight: '1px solid rgba(224,224,224,1)', height: '45px', alignItems: 'center', paddingRight: '5px'
            }}
          >
            <Tooltip arrow placement="right" title="De-activated">
              <IconButton
                sx={{ width: "35px", height: "35px" }}
                onClick={() => {
                  deleteUtilityThresholds(rowId, {
                    onSuccess: () => {
                      handleClose();
                      notify.success("Threshold values deleted!")
                    },
                    onError: () => {
                      notify.error("Some error has happen while deleting Threshold values! ")
                    }
                  })
                }}
              >
                <EsgDeleteIcon color="red" />
              </IconButton>
            </Tooltip>
          </Box>
        )
      },
      size: 60,
    },
    {
      accessorKey: "utilityType",
      header: "Utility Type",
      size: 120,
      Cell: ({ cell }: any) => {
        const value: any = cell.getValue();
        if (!value) {
          return "All Utility Type";
        }
        return (
          <Tooltip title={value! as string}>
            <Box sx={{ textAlign: "center", cursor: "pointer" }}>{value}</Box>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: "siteName",
      header: "Site",
      size: 120,
      Cell: ({ cell }: any) => {
        const value: any = cell.getValue();
        if (!value) {
          return "All Sites";
        }
        return (
          <Tooltip title={value! as string}>
            <Box sx={{ textAlign: "center", cursor: "pointer" }}>{value}</Box>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: "sequentialThreshold",
      header: "MoM",
      size: 100,
    },
    {
      accessorKey: "yearlyThreshold",
      header: "YoY",
      size: 100,
    },
  
  ];


  const utilityTypes = useMemo(() => allowedValues?.utilityTypes, [allowedValues]);

  
  
  useEffect(() => {
    const allUtility: UtilityType = {
      id: null,
      name: "All Utility Types",
      allowedUnits: [],
      unitDescription: [],
      financials: false,
      emissions: false,
      consumptions: false,
    };
  
    const allSite: SiteOption = {
      label: "All Sites",
      value: null,
    };
  
    // Safely handle undefined or null utilityTypes
    const utilityTypeArray = Array.isArray(utilityTypes) ? utilityTypes : [];
    const nullIdValue = utilityTypeArray.find((t: UtilityType) => t?.id === null);
    if (!nullIdValue) {
      setUtilityTypeOptions([allUtility, ...utilityTypeArray]);
    } else {
      setUtilityTypeOptions(utilityTypeArray);
    }
  
    // Safely handle undefined or null sitesOptions
    const siteOptionsArray = Array.isArray(sitesOptions) ? sitesOptions : [];
    const nullIdValueSite = siteOptionsArray.find((t: SiteOption) => t?.value === null);
    if (!nullIdValueSite) {
      setSiteOptions([allSite, ...siteOptionsArray]);
    } else {
      setSiteOptions(siteOptionsArray);
    }
    // eslint-disable-next-line
  }, []);
  
  
  


  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {" "}
        {showException ? "Exceptions" : "Add an Exception"}
      </DialogTitle>
      <DialogContent>
        {!showException ? <>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 3 }}>
            <Box>
              <Label12SemiBold>Utility Type</Label12SemiBold>
              <CustomSelect
                IconComponent={EsgDropDownIcon}
                input={<CustomOutlinedInput />}
                displayEmpty
                onChange={(e: any) => setUtilityType(e.target.value)}
              >
                {utilityTypeOptions?.map((item: any) => (
                  <MenuItem
                    id={String(item?.id)}
                    sx={{
                      fontWeight: TYPOGRAPHY_COMMON_STYLES.fontWeightSemiBold,
                      fontSize: 14,
                      lineHeight: "18px",
                      color: '#90959E',
                      '&:hover': {
                        backgroundColor: '#F6F8FA',
                        borderRadius: 5,
                        color: COLORS.Romance,
                      },
                      '&.Mui-selected': {
                        backgroundColor: '#F6F8FA',
                        borderRadius: 5,
                        color: COLORS.Romance,
                      },
                    }}
                    key={item?.id} value={item?.name}>
                    {item?.name}
                  </MenuItem>
                ))}
              </CustomSelect>
            </Box>
            <Box>
              <Label12SemiBold>Site</Label12SemiBold>
              <CustomSelect
                IconComponent={EsgDropDownIcon}
                input={<CustomOutlinedInput />}
                displayEmpty
                onChange={(e: any) => setSelectedSites(e.target.value)}>
                {siteOptions?.map((item: any) => (
                  <MenuItem
                    id={String(item?.id)}
                    sx={{
                      fontWeight: TYPOGRAPHY_COMMON_STYLES.fontWeightSemiBold,
                      fontSize: 14,
                      lineHeight: "18px",
                      color: '#90959E',
                      '&:hover': {
                        backgroundColor: '#F6F8FA',
                        borderRadius: 5,
                        color: COLORS.Romance,
                      },
                      '&.Mui-selected': {
                        backgroundColor: '#F6F8FA',
                        borderRadius: 5,
                        color: COLORS.Romance,
                      },
                    }}
                    key={item?.value} value={item?.value}>
                    {item?.label}
                  </MenuItem>
                ))}
              </CustomSelect>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: 2, my: 2 }}>
            <Box>
              <Label12SemiBold>MoM</Label12SemiBold>
              <Controller
                name="MoM"
                control={control}
                render={() => (
                  <CustomSmallOutlinedInput
                    endAdornment="%"
                    onChange={handleMoMChange}
                  />
                )}
              />
            </Box>
            <Box>
              <Label12SemiBold>YoY</Label12SemiBold>
              <Controller
                name="YoY"
                control={control}
                render={() => (
                  <CustomSmallOutlinedInput
                    endAdornment="%"
                    onChange={handleYoYChange}
                  />
                )}
              />
            </Box>
          </Box>
        </> : (
          <MaterialReactTable
            columns={columns}
            muiTableContainerProps={{
              sx: tableStyle,
            }}
            muiTablePaperProps={{
              sx: {
                borderRadius: "10px",
                boxShadow: "none",
              },
            }}
            muiTopToolbarProps={{
              sx: {
                display: "none",
              },
            }}
            muiBottomToolbarProps={{
              sx: {
                display: "none",
              },
            }}
            muiTableHeadCellProps={{
              sx: {
                lineHeight: "30px",
                "& .MuiBadge-root": {
                  display: "none",
                },
              },
            }}
            muiTableHeadRowProps={{
              sx: {
                "& > th": {
                  paddingTop: 1,
                  paddingBottom: 1,
                },
                height: "auto",
                backgroundColor: "#F8FAFC",
              },
            }}
            muiTableBodyRowProps={{
              sx: {
                height: "45px",
                backgroundColor: "white",
              },
            }}
            data={utilityThresholdData}
            enableColumnResizing
            enableDensityToggle={false}
            initialState={{ density: "compact" }}
            enablePagination={false}
            enableRowVirtualization
          />
        )}
      </DialogContent>
      {!showException && <DialogActions>
        <Grid
          container
          alignItems="center"
          justifyContent="flex-end"
          mb={2}
          ml={2}
        >
          <Grid item>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              onClick={() => handleSaveChange()}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </DialogActions>}
    </Dialog>
  );
};
