import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import { debounce } from "lodash";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Dialog
} from "@mui/material";

import {
  AdaptedCollectorsMissingDocuments,
  getFiscalYearSettings,
  getMissingDocuments,
  getSitesByCountry,
} from "api";

import {
  COLORS,
  EMPTY_CELL_VALUE,
  H6,
  Loading,
  MultiselectControlledField,
  Pagination,
  ROWS_PER_PAGE_OPTIONS,
  UtilityBillType,
  UtilityBillTypeKey,
  usePagination,
} from "components";

import { useGetAllowedValues, useSites, useUtilityBillsYears } from "hooks";
import { useCountriesMultiselect } from "hooks/multiselect/countries";
import { useFilteredSitesMultiselect } from "hooks/multiselect/filteredSites";

import { LOCAL_STORAGE_KEYS, TABLE_HEADERS } from "consts";

import { generateMonths } from "pages/authenticated/dashboards";

import { useUserRole } from "recoils";

import { isNullOrUndefined } from "utils";

import { EsgRecIcon, ParameterBasedEstimateIcon, UtilitySpecificEmissionsFactorsIcon } from "assets";
import { CollectorMonthView } from "./components";
import { MonthCell } from "./components/table/month-cell";
import { MISSING_DOCUMENTS_PAGE_QUERY_PARAMS } from "./consts";

function dataBoxes({ color, title }: { color: string, title: string }) {
  return (
    <Box sx={{
      display: "flex",
      alignItems: "center",
      gap: "8px",
    }}>
      <div style={{
        width: "21px",
        height: "21px",
        backgroundColor: color,
        borderRadius: "4px",
      }} />
      {title}
    </Box>
  )
}

export const MissingDocumentsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const collectorId = searchParams.get(
    MISSING_DOCUMENTS_PAGE_QUERY_PARAMS.COLLECTOR_ID,
  );
  const month = searchParams.get(MISSING_DOCUMENTS_PAGE_QUERY_PARAMS.MONTH);
  const onCollectorMonthViewClose = useCallback(
    () => setSearchParams({}),
    [setSearchParams],
  );

  const userRole = useUserRole();
  const { data: sites } = useSites();

  const result = Array.isArray(sites) ? sites.map((a) => a.id) : [];

  const siteIds = result?.toString();

  const [selectedSiteIds, setSelectedSiteIds] = useState<any>(null);

  const { data: availableYears } = useUtilityBillsYears();
  const { data: allowedValues } = useGetAllowedValues();

  const [fiscalYear, setFiscalYear] = useState("");

  const [startMonth, setStartMonth] = useState("");
  const [endMonth, setEndMonth] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [monthHeaders, setMonthHeaders] = useState<any>([]);
  const [missingData, setMissingData] = useState<any>([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [isActivated, setIsActivated] = useState(false);
  const [isActivatedMissing, setIsActivatedMissing] = useState<boolean>(false);
  const [utilityTypeId, setUtilityTypeId] = useState<any[]>([]);
  const [searchActive, setSearchActive] = useState<boolean>(false);
  const [showAll, setShowAll] = useState<boolean>(true);

  const NONE_PADDING_COLUMNS_IDS = new Set<string>();

  const {
    pageSize,
    pageNumber,
    onPageSizeChange,
    onPageChange,
    setInitialPage,
    updateQueryParamsWithInitialPage,
  } = usePagination({
    pageSizeLocalStorageKey: LOCAL_STORAGE_KEYS.DOCUMENT_SUMMARY_PAGE_SIZE,
  });

  const currentYear = new Date().getFullYear();
  const nextYear = currentYear + 1;

  const fiscalYears = availableYears && [
    ...availableYears.map((yearItem: any) => `${yearItem} Fiscal Year`),
    `${nextYear} Fiscal Year`,
  ];

  const yearValue = parseInt(fiscalYear.replace("Fiscal Year", ""), 10) as number;

  const {
    isCountriesLoading,
    selectedCountry,
    setSelectedCountry,
    countriesList,
  } = useCountriesMultiselect({
    updateExternalStates: setInitialPage,
    getQueryParamsWithExternalChanges: updateQueryParamsWithInitialPage,
    withEmptyOption: false,
  });

  const generateDates = (
    year_Value: number,
    start_Month: string,
    end_Month: string,
  ) => {
    const adjustedYear = start_Month.toUpperCase() === "JAN" ? year_Value : year_Value - 1;
    const startDate = dayjs(`${start_Month} 1, ${adjustedYear}`);
    const endDate = dayjs(`${end_Month} 1, ${year_Value}`).endOf("month");
    const FromDate = startDate.format("YYYY-MM-DD");
    const ToDate = endDate.format("YYYY-MM-DD");
    return { FromDate, ToDate };
  };

  const { isSitesLoading, selectedSites, setSelectedSites, sitesOptions } =
    useFilteredSitesMultiselect({
      selectedCountry,
      updateExternalStates: setInitialPage,
      getQueryParamsWithExternalChanges: updateQueryParamsWithInitialPage,
    });

  const getMonthsfromSettings = () => {
    getFiscalYearSettings()
      .then((fiscalYearSettings: any) => {
        const startMonthValue = fiscalYearSettings.find(
          (setting: any) => setting.name === "company_month_start",
        )?.configValue;
        const endMonthValue = fiscalYearSettings.find(
          (setting: any) => setting.name === "company_month_end",
        )?.configValue;
        setStartMonth(startMonthValue);
        setEndMonth(endMonthValue);
      })
      .catch((error) => {
        console.error("Error fetching fiscal year settings:", error);
      });
  };

  useEffect(() => {
    getMonthsfromSettings();
  });

  useEffect(() => {
    const data = generateMonths(startMonth, endMonth, yearValue);
    setMonthHeaders(data);
  }, [startMonth, endMonth, fiscalYear, yearValue]);

  useEffect(() => {
    const { FromDate: fDate, ToDate: tDate } = generateDates(
      yearValue,
      startMonth,
      endMonth,
    );
    setFromDate(fDate);
    setToDate(tDate);
  }, [yearValue, startMonth, endMonth]);

  const fetchData = async () => {
    setSearchActive(false);
    try {
      setDataLoading(true);
      let data;
      if (userRole.isDataProvider || userRole.isAdmin) {
        data = await getMissingDocuments({
          fromDate,
          toDate,
          utilityTypeIds: utilityTypeId?.map((t) => t.value),
          siteIds:
            selectedSiteIds?.length === 0
              ? siteIds
              : selectedSiteIds && selectedSiteIds.toString(),
        });
      } else {
        data = await getMissingDocuments({
          fromDate,
          toDate,
        });
      }

      if (selectedCountry && selectedCountry.length > 0) {
        let combinedFilteredSites: string[] = [];
        let combinedSelectedSiteNamesArray: string[] = [];

        if (Array.isArray(selectedCountry)) {
          const sitePromises = selectedCountry.map((country) =>
            getSitesByCountry(country.value),
          );
          const results = await Promise.all(sitePromises);
          const responses = results.flat();

          const filtered =
            sites?.filter(
              (site) => site?.name != null && responses.includes(site.name),
            ) ?? [];

          const filteredSiteNames = filtered
            .map((site) => site.name)
            .filter((name): name is string => name != null);
          combinedFilteredSites =
            combinedFilteredSites.concat(filteredSiteNames);

          if (selectedSites && selectedSites.length > 0) {
            const selectedSiteNames = selectedSites
              .map((site) => site.label)
              .filter((label): label is string => label != null);
            combinedSelectedSiteNamesArray =
              combinedSelectedSiteNamesArray.concat(selectedSiteNames);
          }
        } else {
          const response: string[] = await getSitesByCountry(selectedCountry);
          const filtered =
            sites?.filter(
              (site) => site?.name != null && response.includes(site.name),
            ) ?? [];

          const filteredSiteNames = filtered
            .map((site) => site.name)
            .filter((name): name is string => name != null);
          combinedFilteredSites =
            combinedFilteredSites.concat(filteredSiteNames);

          if (selectedSites && selectedSites.length > 0) {
            const selectedSiteNames = selectedSites
              .map((site) => site.label)
              .filter((label): label is string => label != null);
            combinedSelectedSiteNamesArray =
              combinedSelectedSiteNamesArray.concat(selectedSiteNames);
          }
        }

        if (selectedSites && selectedSites.length > 0) {
          const combinedSelectedSiteNamesSet = new Set(
            combinedSelectedSiteNamesArray,
          );
          data = data.filter((item) =>
            combinedSelectedSiteNamesSet.has(item.collector.siteName),
          );
        } else {
          const combinedFilteredSiteNamesSet = new Set(combinedFilteredSites);
          data = data.filter((item) =>
            combinedFilteredSiteNamesSet.has(item.collector.siteName),
          );
        }
      }

      setMissingData(data);
      setDataLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setDataLoading(false);
    }
  };

  const monthNamesMap: { [key: string]: number } = {
    Jan: 1,
    Feb: 2,
    Mar: 3,
    Apr: 4,
    May: 5,
    Jun: 6,
    Jul: 7,
    Aug: 8,
    Sep: 9,
    Oct: 10,
    Nov: 11,
    Dec: 12,
  };

  const tableRows = useMemo(() => missingData || [], [missingData]);
  const activeCollectors = useMemo(() => missingData.filter((row: any) => row.collector.enabled && (new Date(toDate) > new Date(row.collector.startDate))) || [], [missingData, toDate]);
  const missingCollectors = useMemo(() => missingData.filter((row: any) => {
    let monthsToCheck = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
    const monthMap: { [key: string]: number } = {
      Jan: 1,
      Feb: 2,
      Mar: 3,
      Apr: 4,
      May: 5,
      Jun: 6,
      Jul: 7,
      Aug: 8,
      Sep: 9,
      Oct: 10,
      Nov: 11,
      Dec: 12,
    };
    if ((currentYear + 1) === yearValue) {
      const lastMonthToCheck: number = parseInt(dayjs().format("MM"), 10) - 1;
      const startMonthToCheck: number = monthMap[startMonth] - 1;
      monthsToCheck = monthsToCheck.slice(startMonthToCheck, lastMonthToCheck);
    }
    const hasMissingData = monthsToCheck.some(currentMonth =>
      row.months[currentMonth] && row.months[currentMonth].usage === null
    );

    return hasMissingData;
  }) || [], [missingData, yearValue, currentYear, startMonth]);


  const collectorMissingDocuments = useMemo(
    () => tableRows.find((r: any) => String(r.id) === collectorId)!,
    [collectorId, tableRows],
  );

  const filteredSelectedData = useMemo(() =>
    selectedSites.flatMap(site =>
      sites?.filter(
        row => row.name && row.name.toLowerCase() === site.label.toLowerCase(),
      ) || [],
    ),
    [selectedSites, sites]
  );


  useEffect(() => {
    const selectedIds = filteredSelectedData.map((site) => site.id);

    // Debounce the setSelectedSiteIds function
    const debouncedSetSelectedSiteIds = debounce(() => {
      setSelectedSiteIds(selectedIds);
    }, 300);

    debouncedSetSelectedSiteIds();

    // Cleanup function to cancel debounce on unmount or change
    return () => {
      debouncedSetSelectedSiteIds.cancel();
    };
  }, [filteredSelectedData]);

  useEffect(() => {
    setSelectedSites([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countriesList, selectedCountry]);

  const monthColumns = monthHeaders.map((monthValue: string) => { // eslint-disable-line
    if (!monthValue) return null;
    const [year, monthName] = monthValue.split(" ");
    const monthNumber = monthNamesMap[monthName];
    const paddedMonthNumber =
      monthNumber && monthNumber.toString().padStart(2, "0");
    const formattedYear = year.slice(-2);

    NONE_PADDING_COLUMNS_IDS.add(`${year}-${paddedMonthNumber}`);

    return {
      id: `${year}-${paddedMonthNumber}`,
      header: fiscalYear === "" ? monthName : `${monthName}'${formattedYear}`,
      accessorKey: `months.${paddedMonthNumber}`,
      Cell: MonthCell,
      enableSorting: false,
      minSize: 120,
      size: 120,
    };
  });

  // Columns
  const MISSING_DOCUMENTS_COLUMNS: MRT_ColumnDef<AdaptedCollectorsMissingDocuments>[] =
    [
      {
        header: "Site",
        accessorKey: "collector.siteName",
        Cell: ({ cell }: { cell: any }) => {
          const value = cell.getValue();
          const isEnabled = cell.row.original.collector.enabled;
          const hasUsage = Object.values(cell.row.original.months).some((collector: any) => collector.usage !== null);
          const hasHistoricalData = cell.row.original.collector.providerName !== null || cell.row.original.collector.accountNumber !== null || cell.row.original.collector.meterNumber !== null;

          if (isNullOrUndefined(value)) {
            return (
              <Box sx={{ color: !isEnabled && !hasUsage ? "#dddddd" : !hasHistoricalData ? "#dddddd" : "", padding: "0 16px" }}>
                {EMPTY_CELL_VALUE}
              </Box>
            );
          }
          return (
            <Tooltip title={value as string}>
              <Box sx={{ color: !isEnabled && !hasUsage ? "#dddddd" : !hasHistoricalData ? "#dddddd" : "", padding: "0 16px", textOverflow: "ellipsis", maxWidth: "159px" }}>
                {value as string}
              </Box>
            </Tooltip>
          );
        },
        enableSorting: false,
        enablePinning: true,
        minSize: 160,
        size: 160,
      },
      {
        header: "Source",
        accessorKey: "collector.providerName",
        // eslint-disable-next-line
        Cell: ({ cell }: { cell: any }) => {
          const value = cell.getValue();
          const isEnabled = cell.row.original.collector.enabled;
          const hasUsage = Object.values(cell.row.original.months).some((collector: any) => collector.usage !== null);
          const hasHistoricalData = cell.row.original.collector.providerName !== null || cell.row.original.collector.accountNumber !== null || cell.row.original.collector.meterNumber !== null;

          if (isNullOrUndefined(value)) {
            return (
              <Box sx={{ color: !isEnabled && !hasUsage ? "#dddddd" : !hasHistoricalData ? "#dddddd" : "", padding: "0 16px" }}>
                {EMPTY_CELL_VALUE}
              </Box>
            );
          }
          return (
            <Box sx={{ display: "flex" }}>
              <Tooltip title={value as string}>
                <Box sx={{ color: !isEnabled && !hasUsage ? "#dddddd" : !hasHistoricalData ? "#dddddd" : "", padding: "0 16px", textOverflow: "ellipsis", maxWidth: "179px" }}>
                  {value as string}
                </Box>
              </Tooltip>
            </Box>
          );
        },
        enableSorting: false,
        enablePinning: true,
        minSize: 180,
        size: 180,
      },
      {
        header: TABLE_HEADERS.accountNumber,
        accessorKey: "collector.accountNumber",
        // eslint-disable-next-line
        Cell: ({ cell }: { cell: any }) => {
          const value = cell.getValue();
          const isEnabled = cell.row.original.collector.enabled;
          const hasUsage = Object.values(cell.row.original.months).some((collector: any) => collector.usage !== null);
          const hasHistoricalData = cell.row.original.collector.providerName !== null || cell.row.original.collector.accountNumber !== null || cell.row.original.collector.meterNumber !== null;

          if (isNullOrUndefined(value)) {
            return (
              <Box sx={{ color: !isEnabled && !hasUsage ? "#dddddd" : !hasHistoricalData ? "#dddddd" : "", padding: "0 16px" }}>
                {EMPTY_CELL_VALUE}
              </Box>
            );
          }
          return (
            <Tooltip title={value as string}>
              <Box sx={{ color: !isEnabled && !hasUsage ? "#dddddd" : !hasHistoricalData ? "#dddddd" : "", padding: "0 16px", textOverflow: "ellipsis", maxWidth: "139px" }}>
                {value as string}
              </Box>
            </Tooltip>
          );
        },
        enableSorting: false,
        enablePinning: true,
        minSize: 140,
        size: 140,
      },
      {
        header: TABLE_HEADERS.meterNumber,
        accessorKey: "collector.meterNumber",
        // eslint-disable-next-line
        Cell: ({ cell }: { cell: any }) => {
          const value = cell.getValue();
          const isEnabled = cell.row.original.collector.enabled;
          const hasUsage = Object.values(cell.row.original.months).some((collector: any) => collector.usage !== null);
          const hasHistoricalData = cell.row.original.collector.providerName !== null || cell.row.original.collector.accountNumber !== null || cell.row.original.collector.meterNumber !== null;

          if (isNullOrUndefined(value)) {
            return (
              <Box sx={{ color: !isEnabled && !hasUsage ? "#dddddd" : !hasHistoricalData ? "#dddddd" : "", padding: "0 16px" }}>
                {EMPTY_CELL_VALUE}
              </Box>
            );
          }
          return (
            <Tooltip title={value as string}>
              <Box sx={{ color: !isEnabled && !hasUsage ? "#dddddd" : !hasHistoricalData ? "#dddddd" : "", padding: "0 16px", textOverflow: "ellipsis", maxWidth: "139px" }}>
                {value as string}
              </Box>
            </Tooltip>
          );
        },
        enableSorting: false,
        enablePinning: true,
        minSize: 140,
        size: 140,
      },
      {
        header: "Type",
        accessorKey: "collector.utilityTypeName",
        // eslint-disable-next-line
        Cell: ({ cell }: { cell: any }) => {
          const value = cell.getValue() as UtilityBillTypeKey;
          const isEnabled = cell.row.original.collector.enabled;
          const hasUsage = Object.values(cell.row.original.months).some((collector: any) => collector.usage !== null);
          const hasHistoricalData = cell.row.original.collector.providerName !== null || cell.row.original.collector.accountNumber !== null || cell.row.original.collector.meterNumber !== null;

          if (isNullOrUndefined(value)) {
            return (
              <Box sx={{ color: !isEnabled && !hasUsage ? "#dddddd" : !hasHistoricalData ? "#dddddd" : "", padding: "0 16px" }}>
                {EMPTY_CELL_VALUE}
              </Box>
            );
          }

          return (
            <Box sx={{ color: !isEnabled && !hasUsage ? "#dddddd" : !hasHistoricalData ? "#dddddd" : "", padding: "0 16px", textOverflow: "ellipsis", maxWidth: "139px" }}>
              <UtilityBillType type={value} />
            </Box>
          );
        },
        enableSorting: false,
        minSize: 140,
        size: 140,
      },
      {
        accessorKey: "collector.estimate",
        header: "Tags",
        // eslint-disable-next-line
        Cell: ({ cell }: { cell: any }) => {
          const showEstimateIndicator = cell.row.original.collector.estimate;
          const showSpendBasedIndicator = cell.row?.original?.spendBased;
          const showRecIndicator = cell.row?.original?.collector?.recs;

          return (
            <Box sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              height: "24px",
              padding: "0 16px",
            }}>
              {showSpendBasedIndicator
                ? <Tooltip
                  placement="top"
                  title="There are Spend Based Estimations at this site"
                >
                  <UtilitySpecificEmissionsFactorsIcon color={COLORS.Mainblue} />
                </Tooltip>
                : <Box sx={{ width: "24px", height: "24px" }} />
              }
              {showEstimateIndicator
                ? <Tooltip
                  placement="top"
                  title="There are Parameter Based Estimations at this site"
                >
                  <ParameterBasedEstimateIcon color={COLORS.Mainblue} />
                </Tooltip>
                : <Box sx={{ width: "24px", height: "24px" }} />
              }
              {showRecIndicator
                ? <Tooltip
                  placement="top"
                  title="This site has a REC applied to its emissions"
                >
                  <EsgRecIcon color={COLORS.Mainblue} />
                </Tooltip>
                : <Box sx={{ width: "24px", height: "24px" }} />
              }
            </Box>
          );
        },
        enableSorting: false,
        minSize: 150,
        size: 150,
      },
      ...monthColumns,
    ];

  const currentTableData = isActivated ? activeCollectors : isActivatedMissing ? missingCollectors : tableRows;

  const onResetAll = () => {
    setUtilityTypeId([])
    setSelectedSites([]);
    setSearchParams({});
    setFiscalYear("");
    setSelectedCountry([]);
    setIsActivated(false);
    setSearchActive(true);
  };
  useEffect(() => {
    onResetAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!fiscalYear.length && !selectedCountry.length && !utilityTypeId.length && !selectedSites.length) {
      setSearchActive(false);
    } else {
      setSearchActive(true);
    }
  }, [fiscalYear, selectedCountry, utilityTypeId, selectedSites]);

  const handleSwitchChange = () => {
    setDataLoading(true);
    setIsActivated(true);
    setIsActivatedMissing(false);
    setShowAll(false);
    setTimeout(() => {
      setDataLoading(false);
    }, 1000)
  };

  const handleSwitchChangeOnlyMissing = () => {
    setDataLoading(true);
    setIsActivated(false);
    setIsActivatedMissing(true);
    setShowAll(false);
    setTimeout(() => {
      setDataLoading(false);
    }, 1000)
  };

  const handleSwitchChangeShowAll = () => {
    setDataLoading(true);
    setIsActivated(false);
    setIsActivatedMissing(false);
    setShowAll(true);
    setTimeout(() => {
      setDataLoading(false);
    }, 1000)
  };

  const debouncedSwitchChange = isActivated ? () => { } : debounce(handleSwitchChange, 300);
  const debouncedSwitchChangeOnlyMissing = isActivatedMissing ? () => { } : debounce(handleSwitchChangeOnlyMissing, 300);
  const debouncedSwitchChangeShowAll = showAll ? () => { } : debounce(handleSwitchChangeShowAll, 300);

  const utilityTypes = (allowedValues as any)?.utilityTypes?.map((t: any) => ({
    label: t.name,
    value: t.id
  }));

  return (
    <Box sx={{
      margin: "12px 24px"
    }}>
      <H6>Please select your filters and then search to see data below.</H6>
      <br />
      <Box sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
        gap: "16px"
      }}>
        <Box sx={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          gap: "16px",
        }}>
          <Box>
            <InputLabel
              sx={{
                fontSize: "12px",
                color: "black",
                fontWeight: 600,
                marginBottom: "4px",
              }}
              id="fiscal-year-label"
            >
              Fiscal Year
            </InputLabel>
            <FormControl sx={{ width: "200px" }}>
              <Select
                displayEmpty
                sx={{ height: "36px", borderRadius: "8px" }}
                labelId="fiscal-year-label"
                id="fiscal-year-select"
                value={fiscalYear}
                onChange={(event) => setFiscalYear(event.target.value as string)}
                placeholder="Fiscal Year"
                renderValue={(selected) => {
                  if (!selected) {
                    return <p style={{ color: '#35495070', margin: 0 }}>Select Fiscal Year</p>;
                  }

                  return selected;
                }}
              >
                {fiscalYears &&
                  fiscalYears.map((year: any) => (
                    <MenuItem key={year} value={year} sx={{ color: COLORS.Mainblue, fontWeight: 600 }}>
                      {year}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
          <Box
            sx={{ width: "200px" }}
          >
            <InputLabel
              sx={{
                fontSize: "12px",
                color: "black",
                fontWeight: 600,
                marginBottom: "4px",
              }}
            >
              Country
            </InputLabel>
            <MultiselectControlledField
              disabled={isCountriesLoading}
              options={countriesList}
              selectedValues={selectedCountry}
              setSelectedValues={setSelectedCountry}
              isAllSelectedOnEmptySelection
            />
          </Box>
          {(allowedValues as any)?.utilityTypes && <Box
            sx={{ width: "200px" }}
          >
            <InputLabel
              sx={{
                fontSize: "12px",
                color: "black",
                fontWeight: 600,
                marginBottom: "4px",
              }}
            >
              Utility Type
            </InputLabel>
            <MultiselectControlledField
              options={utilityTypes}
              selectedValues={utilityTypeId}
              setSelectedValues={setUtilityTypeId}
              isAllSelectedOnEmptySelection
            />
          </Box>}
          <Box
            sx={{ width: "200px" }}
          >
            <InputLabel
              sx={{
                fontSize: "12px",
                color: "black",
                fontWeight: 600,
                marginBottom: "4px",
              }}
            >
              Sites
            </InputLabel>
            <MultiselectControlledField
              disabled={isSitesLoading}
              selectedValues={selectedSites}
              setSelectedValues={setSelectedSites}
              options={sitesOptions}
              isAllSelectedOnEmptySelection
            />
          </Box>
          <Box sx={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            gap: "16px",
          }}>
            <Box
              sx={{ marginTop: "20px" }}
            >
              <Button
                variant={searchActive ? "contained" : "outlined"}
                onClick={fetchData}
                sx={searchActive ? { borderRadius: "8px", width: "140px" } : { borderRadius: "8px", width: "140px" }}
              >
                Search
              </Button>
            </Box>
            <Box
              sx={{ marginTop: "20px" }}
            >
              <Button variant="outlined" onClick={onResetAll} sx={{ borderRadius: "8px", width: "140px" }} >
                Clear Filters
              </Button>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            minWidth: "fit-content",
            height: "42px",
            padding: "3px",
            border: `1px solid ${COLORS.Mainblue}`,
            borderRadius: "30px",
            marginTop: "16px",
          }}
        >
          <Button variant={isActivated ? "contained" : "text"} sx={{ borderRadius: "30px" }} onClick={debouncedSwitchChange}>Only Active</Button>
          <Button variant={isActivatedMissing ? "contained" : "text"} sx={{ borderRadius: "30px" }} onClick={debouncedSwitchChangeOnlyMissing}>Only Missing</Button>
          <Button variant={showAll ? "contained" : "text"} sx={{ borderRadius: "30px" }} onClick={debouncedSwitchChangeShowAll}>Show all</Button>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "32px",
          flexWrap: "wrap",
          marginTop: "48px",
          fontSize: "12px",
          fontWeight: "500",
        }}
      >
        {dataBoxes({ color: "#FBCBC4", title: "No Data" })}
        {dataBoxes({ color: "#D0EFFD", title: "Extrapolation" })}
        {dataBoxes({ color: "#E4EC81", title: "Partial Data" })}
        {dataBoxes({ color: "#CBF2AC", title: "Complete" })}
        {dataBoxes({ color: "#DDDDDD", title: "Deactivated" })}
      </Box>
      {dataLoading ? (
        <Loading />
      ) : (
        <>
          <Box sx={{
            position: "relative",
            width: "calc(100vw - 151px)",
            marginTop: "32px",
            border: '1px solid #C0D0D4',
            borderRadius: "7px",
            "& > div": {
              margin: 0,
              "& > div": {
                height: "calc(100vh - 440px)",
                maxHeight: "calc(100vh - 440px)",
                overflowY: "auto",
              },
            },
            "& table": {
              "& > thead": {
                position: "sticky",
                top: 0,
                zIndex: 2,
                "& > tr > th:nth-of-type(4)": {
                  borderRight: "1px solid #054254",
                },
                "& > tr > th:nth-of-type(6)": {
                  borderRight: "1px solid #054254",
                },
              },
              "& > tbody": {
                "& > tr > td:nth-of-type(4)": {
                  borderRight: "1px solid #054254",
                },
                "& > tr > td:nth-of-type(6)": {
                  borderRight: "1px solid #054254",
                },
              },
            },
          }}>
            <MaterialReactTable
              muiTableContainerProps={{
                sx: {
                  border: '1px solid #C0D0D4',
                  borderRadius: "7px",
                },
              }}
              muiTablePaperProps={{
                sx: {
                  borderRadius: "10px",
                  boxShadow: "none",
                },
              }}
              muiTopToolbarProps={{
                sx: {
                  display: "none",
                },
              }}
              muiBottomToolbarProps={{
                sx: {
                  display: "none",
                },
              }}
              muiTableHeadCellProps={{
                sx: {
                  borderRight: '1px solid #C0D0D4',
                  color: '#054254',
                  "& .MuiBadge-root": {
                    display: "none",
                  },
                },
              }}
              muiTableBodyCellProps={{
                sx: {
                  maxHeight: "60px",
                  borderRight: '1px solid #C0D0D4',
                  backgroundColor: '#fff',
                  padding: 0,
                  "& > div": {
                    maxHeight: "60px",
                  }
                },
              }}
              columns={MISSING_DOCUMENTS_COLUMNS}
              data={currentTableData}
              enableGlobalFilter={false}
              enablePagination
              enablePinning
              enableStickyHeader
              muiTablePaginationProps={{
                rowsPerPageOptions: [100, 500, 1000],
                showFirstButton: true,
                showLastButton: true,
              }}
              initialState={{
                pagination: { pageSize, pageIndex: pageNumber },
                columnPinning: {
                  left: [
                    'collector.siteName',
                    'collector.providerName',
                    'collector.accountNumber',
                    'collector.meterNumber',
                  ],
                },
              }}
            />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }}>
            <Pagination
              page={pageNumber + 1}
              rowsCount={currentTableData.length || 0}
              rowsPerPage={pageSize}
              rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              onPageChange={onPageChange}
              onRowsPerPageChange={onPageSizeChange}
            />
          </Box>
        </>
      )}
      {!Number.isNaN(yearValue) && collectorId && month && (
        <div>
          <Dialog
            open
            sx={{
              "& > div > div": {
                width: "1480px",
                minWidth: "1480px",
                maxWidth: "1480px",
                height: "fit-content",
                maxHeight: "fit-content",
                marginLeft: "130px",
                borderRadius: "8px",
                "& > div": {
                  minHeight: "100%",
                  "& > div": {
                    padding: "0",
                    height: "100%",
                  }
                },
              }
            }}
          >
            <Box>
              <CollectorMonthView
                year={yearValue}
                month={month}
                collectorMissingDocuments={collectorMissingDocuments}
                onClose={onCollectorMonthViewClose}
              // monthData={monthData}
              />
            </Box>
          </Dialog>
        </div>
      )}
    </Box>
  );
};
