import { Button, ButtonProps } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import React, { ChangeEvent, FunctionComponent } from "react";

import { useNotify } from "hooks";
import { EsgFolderUpload } from "assets";
import { UserRole, useUserRole } from "recoils";

const EXCEL_FILE_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

export const UploadButton: FunctionComponent<{
  label: string;
  uploadApiCall: ({
    file,
    isScope3,
  }: {
    file: FormData;
    isScope3: Boolean;
  }) => Promise<AxiosResponse>;
  fileType?: string;
  accept?: string;
  queryKeyToInvalidate: string;
  availableToRoles: UserRole[];
  extraReqParams?: [string, string][];
  variant?: ButtonProps["variant"];
  isScope3?: Boolean;
  marginTop?: string;
  apiResponse?: any;
  uploaddiseble?:boolean;
  setUploadsdiseble?:any;
}> = ({
  uploadApiCall,
  fileType = EXCEL_FILE_TYPE,
  accept = `${EXCEL_FILE_TYPE}, application/vnd.ms-excel`,
   queryKeyToInvalidate,
  availableToRoles,
  label,
  extraReqParams,
  variant = "contained",
  isScope3 = false,
  marginTop = "10px",
  apiResponse,
  uploaddiseble,
  setUploadsdiseble,
}) => {
  const notify = useNotify();
  const userRole = useUserRole();
  const queryClient = useQueryClient();
  const { mutateAsync: upload, isLoading } = useMutation(uploadApiCall);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    const formData = new FormData();

    if (files) {
      formData.append(
        "file",
        new Blob([files[0]], {
          type: fileType,
        }),
        files[0].name,
      );
      if (extraReqParams) {
        extraReqParams.forEach(([key, value]) => {
          formData.append(key, value);
        });
      }
      setUploadsdiseble(true)
      upload(
        { file: formData, isScope3 },
        {
          onSuccess: (data) => {
            apiResponse(true)
            setTimeout(() => {
              apiResponse(false)
            }, 2000)
            
            if (data?.data?.message! === "Records have been inserted !!! ") {
              notify.success(`Successfully uploaded file ${files[0].name}!`);
            } else {
              notify.success(
                "Duplicate data entries were identified and the most recently uploaded data is being used",
              );
            }

            queryClient.invalidateQueries([queryKeyToInvalidate]);
          },
          onError: (error) => {
            notify.error(
              `Failed to upload file: ${files[0].name}! (${
                (error as AxiosError)?.response?.data || "Internal Server Error"
              })`,
            );
          },
          onSettled() {
            e.target.value = "";
          },
        },
      );
    }
  };

  return (
    <Button
      sx={{ marginTop, width: '240px', height: '36px', borderRadius: '8px' }}
      variant={variant}
      component="label"
      startIcon={<EsgFolderUpload />}
      disabled={isLoading || !userRole.isRoleIn(availableToRoles) || uploaddiseble}
    >
      {label}
      <input hidden accept={accept} type="file" onChange={onChange} />
    </Button>
  );
};
