import { Box, styled, Typography } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useState } from "react";

import { Body1SemiBold, COLORS, Loading, MultiSelectControlledOption } from "components";

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";

import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";


import { ChartWrapper } from "../charts/chartLayoutWrapper";
import {
  CONSUMPTIONS_BY_COUNTRIES_HIGHCHARTS_SEWER_DATA,
  CONSUMPTIONS_BY_FACILITY_TYPES_HIGHCHARTS_SEWER_DATA,
  CONSUMPTIONS_BY_REGION_HIGHCHARTS_DATA,
  CONSUMPTIONS_BY_REGION_HIGHCHARTS_SEWER_DATA,
  CONSUMPTIONS_BY_SITE_HIGHCHARTS_SEWER_DATA,
  CONSUMPTIONS_MONTHLY_HIGHCHARTS_GAS_SEWER_DATA,
  CONSUMPTIONS_YEARLY_HIGHCHARTS_DATA_SEWER_DATA,
  UTILITY_NAMES,
} from "../consts";
import {
  findCommonElements,
  generateMonths,
  parseConsumptionDataMonthly,
  parseCountriesConsumptionData,
  parseFTConsumptionData,
  parseRegionsConsumptionData,
  parseSiteConsumptionData,
  parseYearConsumptionData,
} from "../utils";

type Props = {
  monthConsumptionYear: string;
  setMonthConsumptionYear: (data: string) => void;
  monthlyData: unknown;
  sitesData: unknown;
  yearlyData: unknown;
  regionsData: unknown;
  facilityTypesData: unknown;
  countriesResp: unknown;
  sitesOptions: MultiSelectControlledOption[];
  countryRegionsList: string[];
  facilityTypesList: string[];
  startMonth: string;
  endMonth: string;
  selectedYear: number;
  consumptionLoadingStates: any;
  openAccordian: any;
  businessUnitData: unknown; // eslint-disable-line
  businessUnitList: string[]; // eslint-disable-line
};

export const ConsumptionSewerComponent = ({
  consumptionLoadingStates,
  monthlyData,
  sitesData,
  yearlyData,
  regionsData,
  facilityTypesData,
  countriesResp,
  sitesOptions,
  countryRegionsList,
  facilityTypesList,
  monthConsumptionYear,
  setMonthConsumptionYear,
  startMonth,
  endMonth,
  selectedYear,
  openAccordian,
  businessUnitData, // eslint-disable-line
  businessUnitList, // eslint-disable-line
}: Props) => {

  const Accordion = styled(MuiAccordion)({
    border: "1px solid rgba(0, 0, 0, .125)",
    backgroundColor: "white",
    "&:before": {
      display: "none",
    },
  });

  const CustomAccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, .05)"
        : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    alignItems: "center",
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
      display: "flex",
      alignItems: "center",
      overflow: "hidden", // Prevent content overflow
      whiteSpace: "nowrap", // Keep text inline
    },
    "& .MuiIconButton-root": {
      transition: "transform 0.3s",
      transform: "rotate(0deg)",
    },
    "& .Mui-expanded .MuiIconButton-root": {
      transform: "rotate(90deg)",
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
  }));

  const [
    monthlyConsumptionHighChartsData,
    setMonthlyConsumptionHighChartsData,
  ] = useState(CONSUMPTIONS_MONTHLY_HIGHCHARTS_GAS_SEWER_DATA);

  const [siteConsumptionHighChartsData, setSiteConsumptionHighChartsData] =
    useState(CONSUMPTIONS_BY_SITE_HIGHCHARTS_SEWER_DATA);

  const [yearConsumptionData, setYearConsumptionData] = useState(
    CONSUMPTIONS_YEARLY_HIGHCHARTS_DATA_SEWER_DATA,
  );

  const [regionsConsumptionData, setRegionsConsumptionData] = useState(
    CONSUMPTIONS_BY_REGION_HIGHCHARTS_SEWER_DATA,
  );

  const [ftConsumptionData, setFTConsumptionData] = useState(
    CONSUMPTIONS_BY_FACILITY_TYPES_HIGHCHARTS_SEWER_DATA,
  );

  const [countriesCSata, setCountriesCSData] = useState(
    CONSUMPTIONS_BY_COUNTRIES_HIGHCHARTS_SEWER_DATA,
  );

  const [expanded, setExpanded] = React.useState<string | false>("");

  useEffect(() => {
    if (monthlyData) {
      const parsedData = parseConsumptionDataMonthly(
        monthlyData,
        startMonth,
        endMonth,
        UTILITY_NAMES.SEWER,
      );
      const updatedChartsData = JSON.parse(
        JSON.stringify(CONSUMPTIONS_MONTHLY_HIGHCHARTS_GAS_SEWER_DATA),
      );
      const monthsHeaders = generateMonths(startMonth, endMonth, selectedYear);
      const xAxisData = monthsHeaders.map((monthValue) => {
        const [year, monthName] = monthValue.split(" ");
        const shortYear = year.slice(-2);
        return `${monthName} '${shortYear}`;
      });
      updatedChartsData.series = parsedData;
      updatedChartsData.xAxis.categories = xAxisData;
      updatedChartsData.yAxis.title.text = "ML";
      setMonthlyConsumptionHighChartsData(updatedChartsData);
    }
  }, [monthlyData, monthConsumptionYear, setMonthConsumptionYear, startMonth, endMonth, selectedYear]);

  useEffect(() => {
    if (countriesResp) {
      const parsedData = parseCountriesConsumptionData(
        countriesResp,
        ["US", "Rest of world"],
        UTILITY_NAMES.SEWER,
        startMonth,
        endMonth
      );
      const updatedChartsData = JSON.parse(
        JSON.stringify(CONSUMPTIONS_BY_COUNTRIES_HIGHCHARTS_SEWER_DATA),
      );
      updatedChartsData.xAxis.categories = ["US", "Rest of world"];
      updatedChartsData.series = parsedData;
      updatedChartsData.yAxis.title.text = "ML";
      setCountriesCSData(updatedChartsData);
    }
  }, [countriesResp, startMonth, endMonth]);

  useEffect(() => {
    if (yearlyData) {
      const parsedData = parseYearConsumptionData(
        yearlyData,
        "consumption",
        UTILITY_NAMES.SEWER,
        startMonth,
        endMonth
      );
      const updatedChartsData = JSON.parse(
        JSON.stringify(CONSUMPTIONS_YEARLY_HIGHCHARTS_DATA_SEWER_DATA),
      );
      updatedChartsData.xAxis.categories = Object.keys(yearlyData)
        .sort()
        .map((e) => `FY${e}`);
      updatedChartsData.series = parsedData;
      updatedChartsData.yAxis.title.text = "ML";
      setYearConsumptionData(updatedChartsData);
    }
  }, [yearlyData, startMonth, endMonth]);

  useEffect(() => {
    if (regionsData) {
      const parsedData = parseRegionsConsumptionData(
        regionsData,
        countryRegionsList,
        UTILITY_NAMES.SEWER,
        startMonth,
        endMonth
      );
      const updatedChartsData = JSON.parse(
        JSON.stringify(CONSUMPTIONS_BY_REGION_HIGHCHARTS_DATA), // TODO: Change this for the correct
      );
      updatedChartsData.xAxis.categories = countryRegionsList;
      updatedChartsData.series = parsedData;
      updatedChartsData.yAxis.title.text = "ML";
      setRegionsConsumptionData(updatedChartsData);
    }
  }, [countryRegionsList, regionsData, startMonth, endMonth]);

  useEffect(() => {
    if (facilityTypesData) {
      const parsedData = parseFTConsumptionData(
        facilityTypesData,
        facilityTypesList,
        UTILITY_NAMES.SEWER,
        startMonth,
        endMonth
      );
      const updatedChartsData = JSON.parse(
        JSON.stringify(CONSUMPTIONS_BY_FACILITY_TYPES_HIGHCHARTS_SEWER_DATA), // TODO: Change this for the correct
      );
      updatedChartsData.xAxis.categories = facilityTypesList;
      updatedChartsData.series = parsedData;
      updatedChartsData.yAxis.title.text = "ML";
      setFTConsumptionData(updatedChartsData);
    }
  }, [facilityTypesData, facilityTypesList, startMonth, endMonth]);

  useEffect(() => {
    if (sitesData) {
      const parsedData = parseSiteConsumptionData(
        sitesData,
        sitesOptions,
        UTILITY_NAMES.SEWER,
        startMonth,
        endMonth
      );
      const updatedChartsData = JSON.parse(
        JSON.stringify(CONSUMPTIONS_BY_SITE_HIGHCHARTS_SEWER_DATA),
      );
      updatedChartsData.xAxis.categories = sitesOptions.map((e) => e.label);
      const indexArraymain: any[] = [];

      parsedData.filter((e: any) => {
        const indexArray: any[] = [];
        e.data.forEach((key: any, index: any) => {
          if (key.y === 0) {
            indexArray.push(index);
          }
        });
        return indexArraymain.push(indexArray);
      });

      const commonElements = findCommonElements(...indexArraymain);
      const modifiedData = parsedData.map((series: any) => ({
        name: `FY${series.name}`, // Append 'FY' before the year
        data: series.data,
      }));

      updatedChartsData.series = modifiedData;
      const sort = commonElements.sort((a, b) => b - a);
      sort.forEach((element: Number) => {
        updatedChartsData.xAxis.categories.splice(Number(element), 1);
        parsedData.filter((e: any) => e.data.splice(Number(element), 1));
      });
      updatedChartsData.yAxis.title.text = "ML";
      setSiteConsumptionHighChartsData(updatedChartsData);
    }
  }, [sitesData, sitesOptions, startMonth, endMonth]);

  useEffect(() => {
    openAccordian(expanded)
  }, [expanded, openAccordian])

  const datacheck = (datares: any) => {
    const hasNonZeroValue = datares?.series?.some((item: any) => {
      if (Array.isArray(item?.data)) {

        return item.data.some((value: number | null) => value !== null && value !== 0);
      }
      return false;
    });

    return !!hasNonZeroValue;
  };

  const dataut = siteConsumptionHighChartsData.series.map((item) => {
    if (item.data.length > 0) {

      return false;
    }
    return true;
  })

  const hasFalseValue = dataut.some(item => item === false);
  const monthlyConsumption = datacheck(monthlyConsumptionHighChartsData)
  const hasFalseyearConsumption = datacheck(yearConsumptionData)
  const consumptionbyRegion = datacheck(regionsConsumptionData)
  const ftConsumption = datacheck(ftConsumptionData)
  const countries = datacheck(countriesCSata)

  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);

  const handleChange =
    (panel: string) => async (event: React.SyntheticEvent, isExpanded: boolean) => {
      if (isExpanded) {
        setLoading(true);
        setExpanded(panel);

        // Simulate a 4-second loader
        setTimeout(() => {
          setLoading(false);
        }, 4000);
      } else {
        setExpanded(false);
      }
    };

  return (
    <Box sx={{ mx: 4 }}>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        sx={{
          my: 2,
          background: "white",
          // mx: 4,
        }}
      >
        <CustomAccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
        >
          <ArrowForwardIosSharpIcon
            style={{
              height: "14px",
              width: "14px",
              marginRight: "14px",
              transform:
                expanded === "panel1" ? "rotate(90deg)" : "rotate(0deg)",
            }}
          />
          <Body1SemiBold>Time Period</Body1SemiBold>
        </CustomAccordionSummary>
        <AccordionDetails>
          {expanded === "panel1" &&
            <>
              {/* Consumption Month on Month */}
              <ChartWrapper
                label={
                  <Typography
                    sx={{
                      color: monthlyConsumption
                        ? COLORS.lightgray
                        : "#00000042",
                      fontSize: "16px",
                      fontFamily: "Manrope",
                      fontWeight: "600"
                    }}
                  >
                    Consumption Month on Month
                  </Typography>
                }
                selectedYear={monthConsumptionYear}
                setSelectedYear={setMonthConsumptionYear}
                id="ConsumptionMonth"
              >
                {consumptionLoadingStates?.getSSConsumptionDataLoading || loading ? (<Loading />) : monthlyConsumption ?
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={monthlyConsumptionHighChartsData}
                  />
                  : <Box sx={{ backgroundColor: "#f8f8f8", height: "300px" }}> <Typography sx={{ textAlign: "center", alignItems: "center", pt: "100px", fontSize: "25px" }}>No data available</Typography></Box>
                }
              </ChartWrapper>

              {/* Consumption Year on Year */}
              <ChartWrapper
                label={
                  <Typography
                    sx={{
                      color: hasFalseyearConsumption
                        ? COLORS.lightgray
                        : "#00000042",
                      fontSize: "16px",
                      fontFamily: "Manrope",
                      fontWeight: "600"
                    }}
                  >
                    Consumption Year on Year
                  </Typography>
                }
                id="ConsumptionYear"
              >
                {consumptionLoadingStates?.getConsumptionYearLoading || loading ? (<Loading />) : hasFalseyearConsumption ?
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={yearConsumptionData}

                  />
                  : <Box sx={{ backgroundColor: "#f8f8f8", height: "300px" }}> <Typography sx={{ textAlign: "center", alignItems: "center", pt: "100px", fontSize: "25px" }}>No data available</Typography></Box>
                }
              </ChartWrapper>
            </>}
        </AccordionDetails>
      </Accordion>

      {/* Site Type */}
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
        sx={{
          my: 2,
          background: "white",
        }}
      >
        <CustomAccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
        >
          <ArrowForwardIosSharpIcon
            style={{
              height: "14px",
              width: "14px",
              marginRight: "14px",
              transform:
                expanded === "panel2" ? "rotate(90deg)" : "rotate(0deg)",
            }}
          />
          <Body1SemiBold>Site Type</Body1SemiBold>
        </CustomAccordionSummary>

        <AccordionDetails>
          {expanded === "panel2" &&
            <>
              {/* Consumption by Facility type */}
              <ChartWrapper
                styles={{ width: "50%" }}
                label={
                  <Typography
                    sx={{
                      color: ftConsumption
                        ? COLORS.lightgray
                        : "#00000042",
                      fontSize: "16px",
                      fontFamily: "Manrope",
                      fontWeight: "600"
                    }}
                  >
                    Consumption by Facility type
                  </Typography>
                }
                id="ConsumptionbyFacility"
              >
                {consumptionLoadingStates?.getConsumptionFacilityTypeLoading || loading ? (<Loading />) : ftConsumption ?
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={ftConsumptionData}

                  />
                  : <Box sx={{ backgroundColor: "#f8f8f8", height: "300px" }}> <Typography sx={{ textAlign: "center", alignItems: "center", pt: "100px", fontSize: "25px" }}>No data available</Typography></Box>
                }
              </ChartWrapper>
            </>}
        </AccordionDetails>
      </Accordion>

      {/* Region / Country */}
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
        sx={{
          my: 2,
          background: "white",
          // mx: 4,
        }}
      >
        <CustomAccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
        >
          <ArrowForwardIosSharpIcon
            style={{
              height: "14px",
              width: "14px",
              marginRight: "14px",
              transform:
                expanded === "panel3" ? "rotate(90deg)" : "rotate(0deg)",
            }}
          />
          <Body1SemiBold>Region / Country</Body1SemiBold>
        </CustomAccordionSummary>
        <AccordionDetails>
          {expanded === "panel3" &&
            <>
              {/* Consumption by Region */}
              <ChartWrapper
                styles={{ width: "50%" }}
                label={
                  <Typography
                    sx={{
                      color: consumptionbyRegion
                        ? COLORS.lightgray
                        : "#00000042",
                      fontSize: "16px",
                      fontFamily: "Manrope",
                      fontWeight: "600"
                    }}
                  >
                    Consumption by Region
                  </Typography>
                }
                id="ConsumptionbyRegion"
              >
                {consumptionLoadingStates?.getConsumptionRegionLoading || loading ? (<Loading />) : consumptionbyRegion ?
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={regionsConsumptionData}

                  />
                  : <Box sx={{ backgroundColor: "#f8f8f8", height: "300px" }}> <Typography sx={{ textAlign: "center", alignItems: "center", pt: "100px", fontSize: "25px" }}>No data available</Typography></Box>
                }
              </ChartWrapper>

              {/* Consumption by US vs Rest of world */}
              <ChartWrapper
                styles={{ width: "50%" }}
                label={
                  <Typography
                    sx={{
                      color: countries
                        ? COLORS.lightgray
                        : "#00000042",
                      fontSize: "16px",
                      fontFamily: "Manrope",
                      fontWeight: "600"
                    }}
                  >
                    Consumption by US vs Rest of world
                  </Typography>
                }
                id="ConsumptionbyUS"
              >
                {consumptionLoadingStates?.getConsumptionByCountriesLoading || loading ? (<Loading />) : countries ?
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={countriesCSata}
                  />
                  : <Box sx={{ backgroundColor: "#f8f8f8", height: "300px" }}> <Typography sx={{ textAlign: "center", alignItems: "center", pt: "100px", fontSize: "25px" }}>No data available</Typography></Box>
                }
              </ChartWrapper>
            </>}
        </AccordionDetails>
      </Accordion>

      {/* Business Unit / Site Type */}
      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
        sx={{
          my: 2,
          background: "white",
          // mx: 4,
        }}
      >
        <CustomAccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
        >
          <ArrowForwardIosSharpIcon
            style={{
              height: "14px",
              width: "14px",
              marginRight: "14px",
              transform:
                expanded === "panel4" ? "rotate(90deg)" : "rotate(0deg)",
            }}
          />
          <Body1SemiBold>Business Unit / Site Type</Body1SemiBold>
        </CustomAccordionSummary>
        <AccordionDetails>
          {expanded === "panel4" &&
            <>
              {/* Consumption by Site */}
              <ChartWrapper
                label={
                  <Typography
                    sx={{
                      color: hasFalseValue
                        ? COLORS.lightgray
                        : "#00000042",
                      fontSize: "16px",
                      fontFamily: "Manrope",
                      fontWeight: "600"
                    }}
                  >
                    Consumption by Site
                  </Typography>
                }
                id="ConsumptionbySite"
              >
                {consumptionLoadingStates?.getConsumptionSiteLoading || loading ? (<Loading />) : hasFalseValue ?
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={siteConsumptionHighChartsData}
                  />
                  : <Box sx={{ backgroundColor: "#f8f8f8", height: "300px" }}> <Typography sx={{ textAlign: "center", alignItems: "center", pt: "100px", fontSize: "25px" }}>No data available</Typography></Box>
                }
              </ChartWrapper>
            </>}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
