import { useState } from "react";

export const useChartsDataStateHook = () => {
  const [estimatesMonthlyData, setEstimatesMonthlyData] = useState(null);
  const [estimatesBySiteData, setEstimatesBySiteData] = useState(null);
  const [monthlyData, setMonthlyData] = useState(null);
  const [sitesData, setSitesData] = useState(null);
  const [yearlyData, setYearlyData] = useState(null);
  const [yearlyScopeData, setYearlyScopeData] = useState(null);
  const [regionsData, setRegionsData] = useState(null);
  const [utilityData, setUtilityData] = useState(null);
  const [facilityTypesData, setFacilityTypesData] = useState(null);
  const [businessUnitData, setBusinessUnitData] = useState(null);
  const [scopesResp, setScopesResp] = useState(null);
  const [countriesResp, setCountriesResp] = useState(null);

  const [monthlyFinancialData, setMonthlyFinancialData] = useState(null);
  const [sitesFinancialData, setSitesFinancialData] = useState(null);
  const [yearlyFinancialData, setYearlyFinancialData] = useState(null);
  const [regionsFinancialData, setRegionsFinancialData] = useState(null);
  const [utilityFinancialData, setUtilityFinancialData] = useState(null);
  const [facilityTypesFinancialData, setFacilityTypesFinancialData] = useState(null);
  const [businessUnitFinancialData, setbusinessUnitFinancialData] = useState(null);
  const [countriesFinancialResp, setCountriesFinancialResp] = useState(null);

  const [siteSpecificAccData, setSiteSpecificAccData] = useState(null);
  const [siteSpecificConsumptionData, setSiteSpecificConsumptionData] = useState(null);
  const [siteSpendAccData, setSiteSpendAccData] = useState(null);
  const [unitLevelCost, setUnitLevelCost] = useState(null);
  const [financialEMSpend, setFinancialEMSpend] = useState(null);

  const [consumptionMonthlyData, setConsumptionMonthlyData] = useState(null);
  const [consumptionSitesData, setConsumptionSitesData] = useState(null);
  const [consumptionYearlyData, setConsumptionYearlyData] = useState(null);
  const [consumptionRegionsData, setConsumptionRegionsData] = useState(null);
  const [consumptionFacilityTypesData, setConsumptionFacilityTypesData] = useState(null);
  const [consumptionBusinessUnitData, setconsumptionBusinessUnitData] = useState(null);
  const [consumptionCountriesResp, setConsumptionCountriesResp] = useState(null);
  const [monthlyScopeData, setMonthlyScopeData] = useState(null);
 

  return {
    
    monthlyData,
    setMonthlyData,
    sitesData,
    setSitesData,
    yearlyData,
    setYearlyData,
    regionsData,
    setRegionsData,
    utilityData,
    setUtilityData,
    facilityTypesData,
    setFacilityTypesData,
    businessUnitData,
    setBusinessUnitData,
    scopesResp,
    setScopesResp,
    countriesResp,
    setCountriesResp,
    monthlyFinancialData,
    setMonthlyFinancialData,
    sitesFinancialData,
    setSitesFinancialData,
    yearlyFinancialData,
    setYearlyFinancialData,
    regionsFinancialData,
    setRegionsFinancialData,
    utilityFinancialData,
    setUtilityFinancialData,
    facilityTypesFinancialData,
    setFacilityTypesFinancialData,
    businessUnitFinancialData,
    setbusinessUnitFinancialData,
    countriesFinancialResp,
    setCountriesFinancialResp,
    unitLevelCost,
    setUnitLevelCost,
    financialEMSpend,
    setFinancialEMSpend,
    siteSpecificAccData,
    setSiteSpecificAccData,
    siteSpecificConsumptionData,
    setSiteSpecificConsumptionData,
    siteSpendAccData,
    setSiteSpendAccData,
    consumptionMonthlyData,
    setConsumptionMonthlyData,
    consumptionSitesData,
    setConsumptionSitesData,
    consumptionYearlyData,
    setConsumptionYearlyData,
    consumptionCountriesResp,
    setConsumptionCountriesResp,
    consumptionFacilityTypesData,
    setConsumptionFacilityTypesData,
    consumptionBusinessUnitData,
    setconsumptionBusinessUnitData,
    consumptionRegionsData,
    setConsumptionRegionsData,
    estimatesMonthlyData,
    setEstimatesMonthlyData,
    estimatesBySiteData,
    setEstimatesBySiteData,
    monthlyScopeData,
    setMonthlyScopeData,
    yearlyScopeData,
    setYearlyScopeData,
  };
};
