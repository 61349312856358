/* eslint-disable */
import {
  Box,
  Button,
  ButtonBase,
  Divider,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  deleteEmissionFactorsByCollector,
  getEmissionFactorsByCollector,
} from "api/rec";

import {
  COLORS,
  DeleteDialog,
  H4,
  Label14Medium,
  Loading,
  useDialogControls,
} from "components";

import { useGetAllowedValues, useNotify, useSiteById } from "hooks";


import { EsgDeleteIcon, EsgEditIcon, EsgGreenPowerIcon, EsgLeftArrow, EsgRECPendingIcon, EsgRECUploadedIcon, EsgRedPowerIcon, PlusIcon } from "assets";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import moment from "moment";
import { UtilitySpecificEmissionsFactorsModal } from "./components/site-editor-form/components/UtilitySpecificEmissionsFactorsModal";

export const UtilitySpecificEmissionsFactors: React.FunctionComponent = () => {

  // ---- Hooks ----
  dayjs.extend(timezone);
  const navigate = useNavigate();

  // ---- Const-----
  const { collectorId } = useParams();
  const query = new URLSearchParams(useLocation().search);
  const siteId = query.get('s');
  const deleteDialogControls: any = useDialogControls();
  const notify = useNotify();

  // ---- State ----
  const [isEmissionFactorModalOpen, setEmissionFactorModalOpen] = useState(false);
  const [editEmissionFactorOpen, setEditEmissionFactorOpen] = useState(false);
  const [editEmissionFactorData, setEditEmissionFactorData] = useState(null);
  const [emissionFactorId, setEmissionFactorId] = useState()
  // ---- API's ----
  const { data: site } = useSiteById(siteId as any, true);
  const { data: emissionFactorsData, mutateAsync: getEmissionFactorsData, isLoading } = useMutation(getEmissionFactorsByCollector);
  const { mutateAsync: deleteEmissionFactorsData } = useMutation(deleteEmissionFactorsByCollector);
  
  const { data: allowedValues } = useGetAllowedValues();
  const emissionFactorPreFilledDetails = site?.collectors?.filter((t) => t?.id === parseInt(collectorId!))

  // ---- Use Effects ----
  useEffect(() => {
    getEmissionFactorsData(collectorId)
  }, [collectorId])


  // ----- Table Columns ----
  const emissionFactorDetailColumn = useMemo(
    () => [
      {
        accessorKey: "providerName",
        header: "Utility Provider",
      },
      {
        accessorKey: "utilityType",
        header: "Utility Type",
      },
      {
        accessorKey: "efValue",
        header: "EF Value",
      },
      {
        accessorKey: "efUnit",
        header: "EF Unit",
      },
      {
        accessorKey: "renewableEnergy",
        header: "RE Percentage",
      },
      {
        accessorKey: "startDate",
        header: "EF Start Date",
        Cell: ({ cell }: any) => {
          const value = cell.getValue();

          return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ ml: 1 }} component="span">
                {moment(value).format("DD-MM-YYYY")}
              </Box>
            </Box>
          );
        },
      },
      {
        accessorKey: "endDate",
        header: "EF End Date",
        Cell: ({ cell }: any) => {
          const value = cell.getValue();

          return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ ml: 1 }} component="span">
                {moment(value).format("DD-MM-YYYY")}
              </Box>
            </Box>
          );
        },
      }
    ],
    [],
  ) as MRT_ColumnDef<any>[];


  // ---- Page Functions ----
  const handleCloseEmissionFactorModal = () => {
    setEmissionFactorModalOpen(false);
    setEditEmissionFactorOpen(false);
    setEditEmissionFactorData(null);
    getEmissionFactorsData(collectorId)
  }

  const handleEditCollector = (rowData: any) => {
    setEditEmissionFactorOpen(true);
    setEditEmissionFactorData(rowData);
  };

  const handleDeleteDate = () => {
    deleteEmissionFactorsData({ collectorId, emissionFactorId }, {
        onSuccess: () => {
          getEmissionFactorsData(collectorId);
          deleteDialogControls.close();
          notify.success("Deleted Successfully")
        },
        onError: () =>
          notify.error(
            `Some error has happen while deleting Data!`,
          ),
    })
  }

  if (isLoading) return <Loading />;
  return (
    <Box sx={{ mx: 3 }}>
      {/* Multiple Rec Upload Table */}
      <H4 mt={4} mb={2}>
        Utility Specific Emissions Factors
      </H4>
      <Divider sx={{ mt: 2, mb: 2, backgroundColor: '#054254' }} />
      <br />
      <ButtonBase sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        '&:active': {
          backgroundColor: 'rgba(0, 0, 0, 0.12)', // active state effect
        },
      }}
        onClick={() => navigate(-1)}
      >
        <EsgLeftArrow />
        <Label14Medium>Back to Utility Providers</Label14Medium>
      </ButtonBase>
      <Button
        sx={{ my: 2, borderRadius: '8px' }}
        variant="contained"
        onClick={() => setEmissionFactorModalOpen(true)}
        startIcon={<PlusIcon />}
      >
        Add Utility Specific Emissions Factor
      </Button>
      <br />
      <Box>
        {emissionFactorsData && <MaterialReactTable
          muiTableContainerProps={{
            sx: {
              "& tbody tr td:first-of-type": {
                paddingLeft: "20px",
              },
              "& tbody tr:first-of-type td > p": {
                display: "flex",
                width: "100%",
                justifyContent: "center",
              },
              border: "1px solid #D9D9D9", borderRadius: '10px',
              minWidth: '100%',
              width: '100%',
              maxWidth: '100%',
              '@media (min-width: 600px)': {
                width: '100%',
              },
              '@media (min-width: 960px)': {
                width: '1200px',
              },
              '@media (min-width: 1280px)': {
                width: '1200px',
              },
              '@media (min-width: 1440px)': {
                width: '1200px',
              },
              '@media (min-width: 1680px)': {
                width: '1400px',
              },
              '@media (min-width: 2400px)': {
                width: '100%',
              },
            }
          }}
          muiTablePaperProps={{
            sx: {
              borderRadius: '10px',
              boxShadow: 'none'
            }
          }}
          muiTopToolbarProps={{
            sx: {
              display: 'none'
            }
          }}
          muiBottomToolbarProps={{
            sx: {
              display: 'none'
            }
          }}
          muiTableHeadCellProps={{
            sx: {
              lineHeight: '30px',
              alignItems: 'center',
              color: COLORS.Romance,
              fontWeight: '600',
              fontSize: '14px',
              fontStyle: "Inter",
              '& .MuiBadge-root': {
                display: 'none',
              },
            }
          }}
          muiTableHeadRowProps={{
            sx: {
              height: '40px',
              backgroundColor: '#F8FAFC',
              borderBottomColor: '#F8FAFC',
              textAlign: 'center',
              alignItems: 'center'

            }
          }}
          muiTableBodyRowProps={({ row }) => ({
            onClick: () => handleEditCollector(row?.original),
            sx: { 
              cursor: 'pointer',
              height: '40px',
              backgroundColor: 'white', 
            },
          })}
          columns={emissionFactorDetailColumn}
          data={emissionFactorsData?.data as any}
          enablePagination={false}
          enableDensityToggle={false}
          enableRowActions
          initialState={{
            density: "compact",
          }}
          renderRowActions={({ row }) => {
            return (
              <Box sx={{
                display: "flex",
                borderRight: '1px solid rgba(224,224,224,1)', height: '45px', alignItems: 'center',
              }}>
                {row?.original?.s3Path ? <Tooltip arrow placement="top" title="Document is Uploaded">
                  <IconButton
                    sx={{ width: "35px", height: "35px" }}
                    onClick={() => navigate(`/utility-specific-emissionsFactors-details/${collectorId}`)}>
                    <EsgRECUploadedIcon />
                  </IconButton>
                </Tooltip> : <Tooltip arrow placement="top" title="Add Utility Specific Emissions Factors">
                  <IconButton
                    sx={{ width: "35px", height: "35px" }}
                    onClick={() => navigate(`/utility-specific-emissionsFactors-details/${collectorId}`)}>
                    <EsgRECPendingIcon />
                  </IconButton>
                </Tooltip>}
                <Tooltip arrow placement="right" title="Reactivate">
                  <IconButton
                    sx={{ width: "35px", height: "35px" }}
                    onClick={(e: any) => {
                      setEmissionFactorId(row?.original?.id)
                      e.stopPropagation();
                      deleteDialogControls.open()
                    }}
                  >
                    <EsgDeleteIcon  color="red"/>
                  </IconButton>
                </Tooltip>
              </Box>
            )
          }}
        />}
      </Box>
      <br />

      <UtilitySpecificEmissionsFactorsModal
        isOpen={
          isEmissionFactorModalOpen || editEmissionFactorOpen
        }
        editEmissionFactor={editEmissionFactorData}
        onClose={handleCloseEmissionFactorModal}
        columns={emissionFactorDetailColumn}
        allowedValues={allowedValues}
        emissionFactorData={emissionFactorPreFilledDetails}
      />
      <DeleteDialog
        isOpen={deleteDialogControls.isOpen}
        onCancel={deleteDialogControls.close}
        onDeleteClick={() => handleDeleteDate()}
        target="Utility Specific Emission Factor"
        title="Delete Utility Specific Emission Factor documents?"
      />
    </Box>
  );
};