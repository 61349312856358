import { useCallback } from "react";
import { AllowedValuesDTO } from "api";
import { QUERY_PARAMS_KEYS } from "consts";
import { sortBy } from "utils";

import { useSingeSelectWithFetch } from "./singleselect";
import { useGetAllowedValues } from "../api";
import { OptionalMultiselectProps } from "../multiselect/types";

export const useBusinessUnitSelect = ({
  updateExternalStates,
  getQueryParamsWithExternalChanges,
  withQueryParams = true,
  withEmptyOption = false,
}: OptionalMultiselectProps = {}) => {
  const getOptionsFromData = useCallback(
    (allowedValues?: AllowedValuesDTO) => {
      const businessUnitsAscOrder = sortBy({
        array: (allowedValues || { businessUnits: [] }).businessUnits,
      });

      if (withEmptyOption) {
        businessUnitsAscOrder.push("");
      }
      return businessUnitsAscOrder;
    },
    [withEmptyOption],
  );

  const {
    isLoading: isBusinessUnitLoading,
    setSelectedOption: setSelectedBusinessUnitOption,
    selectedOption: selectedBusinessUnit,
    options: businessUnitList,
    selectedOptionValues: businessUnit,
  } = useSingeSelectWithFetch({
    updateExternalStates,
    getQueryParamsWithExternalChanges,
    withQueryParams,
    useGetData: useGetAllowedValues,
    getOptionsFromData,
    queryKey: QUERY_PARAMS_KEYS.BUSINESS_UNITS,
  });

  return {
    isBusinessUnitLoading,
    setSelectedBusinessUnitOption,
    selectedBusinessUnit,
    businessUnitList,
    businessUnit,
  };
};
